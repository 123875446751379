import React, { Component } from "react";
import consts from "../../config/consts";
import Itinerary from "./components/Itinerary";
import Loading from "./components/Loading";
import "./Sidebar.css";

class Sidebar extends Component {
  constructor() {
    super();

    this.state = {
      mobileOpen: false,
    };
  }

  _toggleMobileOpen() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  _showItinerary() {
    const now = new Date();

    if (now.getTime() >= consts.MAX_DATE.getTime()) {
      return null;
    } else {
      return (
        <Itinerary
          page={this.props.page == null ? 1 : this.props.page}
          firstTown={this.props.firstTown}
          journey={this.props.journey}
          toggleMobileOpen={() => this._toggleMobileOpen()}
        />
      );
    }
  }

  render() {
    return (
      <aside
        className={"sidebar" + (this.state.mobileOpen ? " mobile-open" : "")}
      >
        <div className="sidebar-logo" />
        {this.props.loading && this.props.page === null ? (
          <Loading />
        ) : (
          this._showItinerary()
        )}
      </aside>
    );
  }
}

export default Sidebar;
