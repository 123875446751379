import React, { Component } from "react";
import "./Loading.css";

class Loading extends Component {
  _interval;

  constructor() {
    super();

    this.state = {
      numPoints: 0,
    };
  }

  componentDidMount() {
    this._interval = setInterval(() => {
      if (this.state.numPoints < 3) {
        this.setState({ numPoints: this.state.numPoints + 1 });
      } else {
        this.setState({ numPoints: 0 });
      }
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  _getPoints() {
    let result = "";

    for (let i = 0; i < this.state.numPoints; i++) {
      result += ".";
    }

    return result;
  }

  render() {
    return <div className="loading-wrapper">Cargando{this._getPoints()}</div>;
  }
}

export default Loading;
