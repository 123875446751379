import React, { Component } from "react";

const addZero = num => {
  return (num < 10 ? "0" : "") + num;
};

const formatTime = str => {
  const date = new Date(str);

  return (
    addZero(date.getHours()) +
    ":" +
    addZero(date.getMinutes()) +
    ":" +
    addZero(date.getSeconds())
  );
};

class Town extends Component {
  _timerStart;
  _timerEnd;

  _ref;

  constructor(props) {
    super();

    const town = props.town;
    const timeStart = new Date(town.timeStart);
    const timeEnd = new Date(town.timeEnd);
    const now = new Date();
    const inTheFuture = timeStart.getTime() > now.getTime();
    const active =
      timeStart.getTime() <= now.getTime() &&
      timeEnd.getTime() >= now.getTime();

    if (inTheFuture) {
      this._timerStart = setTimeout(
        () => this.setState({ inTheFuture: false, active: true }),
        timeStart.getTime() - now.getTime()
      );
    }
    if (inTheFuture || active) {
      this._timerEnd = setTimeout(
        () => this.setState({ inTheFuture: false, active: false }),
        timeEnd.getTime() - now.getTime()
      );
    }

    this.state = {
      inTheFuture,
      active,
    };
  }

  componentDidMount() {
    if (this.props.elementRef) {
      this.props.elementRef(this._ref);
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timerStart);
    clearTimeout(this._timerEnd);
  }

  render() {
    const town = this.props.town;

    return (
      <li
        className={
          "town-item clearfix" +
          (this.state.inTheFuture ? " in-the-future" : "")
        }
        key={town.id}
        ref={ref => (this._ref = ref)}
      >
        <span className="town-name">{town.name}</span>
        {this.state.active ? <span className="here">AQU&Iacute;</span> : null}
        <span className="town-time">{formatTime(town.timeStart)}</span>
      </li>
    );
  }
}

export default Town;
