import React, { Component } from "react";
import {
  FaInstagram,
  FaTwitter,
  FaInfoCircle,
  FaArrowLeft,
} from "react-icons/fa";
import "./Header.css";

class Header extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg fixed-top navbar-light navbar-custom">
        <ul className="navbar-nav ml-auto navbar-nav-custom">
          {this.props.currentTown ? (
            <li className="nav-item current-town">
              <span className="nav-link">
                {this.props.currentTown.name}
                <span className="icon">
                  <FaArrowLeft />
                </span>
              </span>
            </li>
          ) : null}
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://twitter.com/ReyesMagosReal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://instagram.com/ReyesMagosReal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </li>
          <li className="nav-item">
            <button
              className="btn nav-link"
              onClick={() => this.props.openInfo()}
            >
              <FaInfoCircle />
            </button>
          </li>
        </ul>

        <div className="header-logo" />
      </nav>
    );
  }
}

export default Header;
