import React, { Component } from "react";
import Map from "ol/Map";
import * as proj from "ol/proj";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import NotStartedModal from "./components/NotStartedModal";
import FinishedModal from "./components/FinishedModal";
import "ol/ol.css";
import "./MapContent.css";

const latlng = (lat, lng) => {
  return proj.transform([lng, lat], "EPSG:4326", "EPSG:3857");
};

class MapContent extends Component {
  _timers = [];

  _animationInterval;

  _map;
  _icon;

  constructor() {
    super();

    this.state = {
      followTheKings: false,
    };
  }

  componentDidMount() {
    this._loadMap();

    if (this.props.currentTown) {
      this._setMarker(this.props.currentTown);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentTown && this.props.currentTown) {
      if (this.props.currentTown.id !== prevProps.currentTown.id) {
        this._moveMarker(prevProps.currentTown, this.props.currentTown);
      }
    } else if (this.props.currentTown) {
      this._setMarker(this.props.currentTown);
    }
  }

  componentWillUnmount() {
    clearInterval(this._animationInterval);
    this._timers.forEach(timer => clearTimeout(timer));

    this._destroyMap();
  }

  _loadMap() {
    this._map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this._createMarkerLayer(),
      ],
      view: new View({
        center: latlng(39.7711432, -4.1653418),
        zoom: 6.2,
      }),
    });
  }

  _createMarkerLayer() {
    this._icon = new Feature({
      geometry: new Point(latlng(40.416729, -3.703339)),
    });

    var iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 157],
        size: [190, 157],
        offset: [0, 0],
        scale: 0.25,
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: "imgs/landmark.png",
        opacity: 0,
      }),
    });

    this._icon.setStyle(iconStyle);

    var vectorSource = new VectorSource({
      features: [this._icon],
    });

    return new VectorLayer({
      source: vectorSource,
    });
  }

  _destroyMap() {
    this._map.setTarget(null);
    this._map = null;
  }

  _setMarker(town) {
    clearInterval(this._animationInterval);
    this._setMarkerPosition(latlng(town.lat, town.lng));
    this._icon
      .getStyle()
      .getImage()
      .setOpacity(1);
  }

  _moveMarker(prevTown, town) {
    const msDuration =
      new Date(town.timeStart).getTime() - new Date().getTime() - 100;

    if (msDuration > 100) {
      const line = new LineString([
        latlng(prevTown.lat, prevTown.lng),
        latlng(town.lat, town.lng),
      ]);

      let current = 0;
      if (this._animationInterval) {
        clearInterval(this._animationInterval);
      }
      this._animationInterval = setInterval(() => {
        current += 100;
        const position = line.getCoordinateAt(current / msDuration);
        this._setMarkerPosition(position);
      }, 100);
      const timer = setTimeout(() => {
        clearInterval(this._animationInterval);
      }, msDuration);
      this._timers.push(timer);
    }
  }

  _setMarkerPosition(position) {
    this._icon.setGeometry(new Point(position));

    if (this.state.followTheKings) {
      this._map.getView().setCenter(position);
    }
  }

  toggleFollowTheKings() {
    const followTheKings = !this.state.followTheKings;
    if (followTheKings) {
      this._map.getView().setCenter(this._icon.getGeometry().getCoordinates());
    }

    this.setState({ followTheKings });
  }

  _followTheKing() {
    return (
      <div
        className="follow-the-kings-wrapper"
        onClick={() => this.toggleFollowTheKings()}
      >
        <span
          className={"checkbox" + (this.state.followTheKings ? " active" : "")}
        >
          <span className="checkbox-inner" />
        </span>
        <span className="label">Seguir a los reyes</span>
      </div>
    );
  }

  render() {
    return (
      <div className="map-wrapper">
        <div className="map" id="map" />
        {this.props.started === true && !this.props.finished
          ? this._followTheKing()
          : null}
        {this.props.started === false ? (
          <NotStartedModal timeStart={this.props.timeStart} />
        ) : null}
        {this.props.finished === true ? (
          <FinishedModal timeEnd={this.props.timeEnd} />
        ) : null}
      </div>
    );
  }
}

export default MapContent;
