import React, { Component } from "react";
import { FaHeart, FaTimes } from "react-icons/fa";
import "./InfoModal.css";
import kingsImg from "./kings.png";

class InfoModal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        window.document.body.classList.add("modal-open");
      } else {
        window.document.body.classList.remove("modal-open");
      }
    }
  }

  _onClickModal() {
    this.props.close();
  }

  _onClickDialog(e) {
    e.stopPropagation();
  }

  render() {
    return (
      <div
        className={"modal info-modal" + (this.props.isOpen ? " d-block" : "")}
        tabIndex="-1"
        role="dialog"
        onClick={() => this._onClickModal()}
      >
        <div
          className="modal-dialog"
          role="document"
          onClick={this._onClickDialog}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="close-wrapper">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.props.close()}
                >
                  <FaTimes />
                </button>
              </div>
              <div className="kings-img">
                <img
                  src={kingsImg}
                  className="img-fluid"
                  alt="Los Reyes Magos"
                />
              </div>
              <div className="kings-msg">
                <h2>Queridos ni&ntilde;os y ni&ntilde;as,</h2>
                <p>
                  Este año no podremos estar con vosotros en la calle,
                  pero pasaremos como cada a&ntilde;o por cada una de vuestras casas.
                  Para que pod&aacute;is ver por donde vamos y sentirnos tan
                  cerca como siempre, de nuevo compartiremos nuestro
                  itinerario con todos vosotros a trav&eacute;s de esta web. Aqu&iacute;
                  podr&eacute;is saber a qu&eacute; hora pasamos por vuestro pueblo, ciudad o
                  municipio. &iexcl;S&iacute;guenos durante la noche m&aacute;s
                  importante del a&ntilde;o!
                </p>
              </div>
              <div className="credits">
                <p className="author">
                  Hecho con{" "}
                  <i className="heart">
                    <FaHeart />
                  </i>{" "}
                  por{" "}
                  <a
                    href="https://rogelioorts.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rogelio Orts
                  </a>
                  .
                </p>
                <p className="vector-author">
                  <a
                    href="https://www.freepik.es/fotos-vectores-gratis/navidad"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vector de Navidad creado por freepik - www.freepik.es
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoModal;
