import React, { Component } from "react";
import "./Countdown.css";

const addZero = num => {
  return (num < 10 ? "0" : "") + num;
};

const getSecondsToGo = (now, startDate) => {
  return Math.max(0, Math.ceil((startDate.getTime() - now.getTime()) / 1000));
};

class Countdown extends Component {
  _interval;

  constructor() {
    super();

    this.state = {
      now: new Date(),
    };
  }

  componentDidMount() {
    this._interval = setInterval(
      () => this.setState({ now: new Date() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    let totalSecondsToGo = getSecondsToGo(this.state.now, this.props.startDate);
    const hoursToGo = Math.floor(totalSecondsToGo / 60 / 60);
    totalSecondsToGo -= hoursToGo * 60 * 60;
    const minsToGo = Math.floor(totalSecondsToGo / 60);
    totalSecondsToGo -= minsToGo * 60;
    const secondsToGo = totalSecondsToGo;

    const strHours = addZero(hoursToGo);
    const strMins = addZero(minsToGo);
    const strSeconds = addZero(secondsToGo);

    return (
      <div className="clock">
        <div className="hours">
          <div className="first">
            <div className="number">{strHours[0]}</div>
          </div>
          <div className="second">
            <div className="number">{strHours[1]}</div>
          </div>
        </div>
        <div className="tick">:</div>
        <div className="minutes">
          <div className="first">
            <div className="number">{strMins[0]}</div>
          </div>
          <div className="second">
            <div className="number">{strMins[1]}</div>
          </div>
        </div>
        <div className="tick">:</div>
        <div className="seconds">
          <div className="first">
            <div className="number">{strSeconds[0]}</div>
          </div>
          <div className="second infinite">
            <div className="number">{strSeconds[1]}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Countdown;
