import React, { Component } from "react";
import consts from "../../../../config/consts";
import ItineraryHeader from "../ItineraryHeader";
import Loading from "../Loading";
import Town from "../Town";
import "./Itinerary.css";

class Itinerary extends Component {
  _searchController;
  _searchSignal;

  _firstTownElement;
  _sectionBody;

  constructor(props) {
    super();

    this.state = {
      minPage: props.page,
      maxPage: props.page,
      loadingPrevious: false,
      loadingNext: false,
      hasNext: true,
      searchResult: null,
      towns: [],
    };
  }

  componentDidMount() {
    this._loadPage(this.state.minPage).then(towns =>
      this.setState({ towns }, () => this._goToFirstTown())
    );

    this._doScrollListener();
  }

  componentWillUnmount() {
    this._destroyScrollListener();
  }

  _goToFirstTown() {
    if (this._firstTownElement) {
      const offsetTop =
        this._firstTownElement.offsetTop - this._sectionBody.offsetTop - 45;
      this._sectionBody.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  }

  _doScrollListener() {
    this._sectionBody.onscroll = () => {
      const bottomLimit =
        this._sectionBody.getElementsByTagName("ul")[0].offsetHeight -
        this._sectionBody.offsetHeight +
        32;
      const scrollTop = this._sectionBody.scrollTop;
      if (scrollTop === bottomLimit) {
        this._loadNext();
      } else if (scrollTop === 0) {
        this._loadPrevious();
      }
    };
  }

  _destroyScrollListener() {
    this._sectionBody.onscroll = null;
  }

  _loadNext() {
    if (this.state.loadingNext || !this.state.hasNext) {
      return;
    }

    const maxPage = this.state.maxPage + 1;
    this.setState({ loadingNext: true });
    this._loadPage(maxPage)
      .then(towns => {
        if (towns.length === 0) {
          this.setState({ loadingNext: false, hasNext: false });
        } else {
          this.setState({
            towns: this.state.towns.concat(towns),
            maxPage,
            loadingNext: false,
          });
        }
      })
      .catch(err => this.setState({ loadingNext: false }));
  }

  _loadPrevious() {
    if (this.state.loadingPrevious || this.state.minPage === 0) {
      return;
    }

    const minPage = this.state.minPage - 1;
    this.setState({ loadingPrevious: true });
    this._loadPage(minPage)
      .then(towns => {
        this.setState(
          {
            towns: towns.concat(this.state.towns),
            minPage,
            loadingPrevious: false,
          },
          () => {
            this._sectionBody.scrollTo({
              top: towns.length * 33,
            });
          }
        );
      })
      .catch(err => this.setState({ loadingPrevious: false }));
  }

  _loadPage(page) {
    const start = page * consts.PAGINATION;
    const end = start + consts.PAGINATION;

    return Promise.resolve(this.props.journey.slice(start, end));
  }

  search(q) {
    clearTimeout(this._searchTimeout);

    if (this._searchController) {
      this._searchController.abort();
    }
    if (!q || q.length < 3 || q.length > 100) {
      this.setState({ searchResult: null });
    } else {
      this._searchTimeout = setTimeout(() => {
        this.setState({ searchResult: this.props.journey.filter(town => {
          return town.name.toLowerCase().indexOf(q.toLowerCase()) > -1;
        }) });
      }, 500);
    }
  }

  render() {
    const firstTown = this.props.firstTown;

    return (
      <div className="sidebar-section">
        <ItineraryHeader
          search={q => this.search(q)}
          toggleMobileOpen={this.props.toggleMobileOpen}
        />
        <div
          className="sidebar-section-body"
          ref={ref => (this._sectionBody = ref)}
        >
          {this.state.loadingPrevious ? <Loading /> : null}
          <ul
            className={
              "towns-list" + (this.state.searchResult !== null ? " d-none" : "")
            }
          >
            {this.state.towns.map(town => (
              <Town
                key={town.id}
                town={town}
                elementRef={
                  firstTown && firstTown.id === town.id
                    ? ref => (this._firstTownElement = ref)
                    : null
                }
              />
            ))}
          </ul>
          {this.state.searchResult !== null ? (
            <ul className="towns-list">
              {this.state.searchResult.map(town => (
                <Town
                  key={town.id}
                  town={town}
                  elementRef={
                    firstTown && firstTown.id === town.id
                      ? ref => (this._firstTownElement = ref)
                      : null
                  }
                />
              ))}
            </ul>
          ) : null}
          {this.state.loadingNext ? <Loading /> : null}
        </div>
      </div>
    );
  }
}

export default Itinerary;
