import React, { Component } from "react";
import "./FinishedModal.css";

class FinishedModal extends Component {
  render() {
    return (
      <div className="map-modal">
        <div className="map-modal-content">
          <h2 className="finished-modal-title">¡Regalos repartidos!</h2>
          <p>
            Ya hemos terminado nuestro intinerario de 2021. Todos los regalos
            han sido repartidos y ya podéis empezar a disfrutar de ellos.
          </p>
          <p>
            Un año m&aacute;s, hemos recorrido <strong>8111 municipios</strong>,
            repartido m&aacute;s de <strong>46 millones de regalos</strong>,
            recorrido una distancia de m&aacute;s de <strong>58.000kms</strong>{" "}
            y todo esto en menos de <strong>12 horas</strong>, con una velocidad
            media de <strong>&iexcl;2km por segundo!</strong>.
          </p>
          <p>
            Y lo más importante, la inmensia mayor&iacute;a de los niños y niñas
            han sido muy muy buenos. ¡Solo hemos repartido{" "}
            <strong>carb&oacute;n a menos de 9</strong> despistados!
          </p>
          <p className="finished-modal-bye">
            &iexcl;Gracias por seguir creyendo en nosotros y nos vemos en 2022!
          </p>
        </div>
      </div>
    );
  }
}

export default FinishedModal;
