import React, { Component } from "react";
import Countdown from "../Countdown";

const addZero = num => {
  return (num < 10 ? "0" : "") + num;
};

const formatTime = date => {
  return addZero(date.getHours()) + ":" + addZero(date.getMinutes());
};

class NotStartedModal extends Component {
  render() {
    const startDate = new Date(this.props.timeStart);
    return (
      <div className="map-modal">
        <div className="map-modal-content">
          <h2>Queridos niños y niñas,</h2>
          <p>
            Este año podr&eacute;is seguir en directo nuestro recorrido por
            todos los municipios de España. Empezamos el d&iacute;a{" "}
            <strong>{startDate.getDate()}</strong> a las{" "}
            <strong>{formatTime(startDate)}</strong> y, durante 12 horas,
            estaremos repartiendo regalos por toda España.
          </p>
          <p>
            <strong>
              ¿Quieres saber cu&aacute;ndo pasamos por tu pueblo o ciudad?
            </strong>{" "}
            En el itinerario podr&aacute;s buscarlo (haz click en la lupa para
            que sea m&aacute;s f&aacute;cil encontrarlo).
          </p>
          <p>
            <strong>
              ¿Quieres seguir en directo como recorremos la geograf&iacute;a
              espa&ntilde;ola repartiendo ilusi&oacute;n?
            </strong>{" "}
            Cuando acabe la siguiente cuentra atr&aacute;s podr&aacute;s
            seguirnos en el mapa, <strong>&iexcl;en directo!</strong>
          </p>
          <Countdown startDate={startDate} />
        </div>
      </div>
    );
  }
}

export default NotStartedModal;
