import React, { Component } from "react";
import { FaSearch, FaTimes, FaTh } from "react-icons/fa";
import "./ItineraryHeader.css";

class ItineraryHeader extends Component {
  _searchInput;

  constructor() {
    super();

    this.state = {
      searcherOpen: false,
    };
  }

  _onClickSearchBtn() {
    const searcherOpen = !this.state.searcherOpen;
    if (searcherOpen) {
      this.props.search(this._searchInput.value);
      this._searchInput.focus();
    } else {
      this.props.search("");
    }
    this.setState({ searcherOpen });
  }

  render() {
    return (
      <div className="sidebar-section-header">
        <h3 onClick={this.props.toggleMobileOpen}>
          <span className="icon">
            <FaTh />
          </span>
          Itinerario
        </h3>

        <div
          className={
            "input-group seacher-wrapper" +
            (this.state.searcherOpen ? " open" : "")
          }
        >
          <input
            type="text"
            className="form-control"
            onKeyUp={e => this.props.search(e.target.value)}
            ref={ref => (this._searchInput = ref)}
          />
          <div className="input-group-append searcher-icon">
            <button
              className="input-group-text"
              onClick={() => this._onClickSearchBtn()}
            >
              {this.state.searcherOpen ? <FaTimes /> : <FaSearch />}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ItineraryHeader;
