import React, { Component } from "react";

class ModalBackdrop extends Component {
  render() {
    return this.props.open ? (
      <div className="modal-backdrop fade show" />
    ) : null;
  }
}

export default ModalBackdrop;
