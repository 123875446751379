import React, { Component } from "react";
import consts from "./config/consts";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import MapContent from "./components/MapContent";
import InfoModal from "./components/InfoModal";
import ModalBackdrop from "./components/ModalBackdrop";
import "./App.css";

function findJourneyIndex(journey) {
  const t = new Date().getTime();
  if (t < journey[0].timeStart) {
    return -1;
  }

  for (let i in journey) {
    if (journey[i].timeEnd > t) {
      return i;
    }
  }

  return journey.length;
}

class App extends Component {
  _loadInterval;
  _startTimer;

  constructor() {
    super();

    this.state = {
      journey: [],
      journeyIndex: -1,
      page: null,
      loading: true,
      timeStart: null,
      timeEnd: null,
      started: null,
      finished: null,
      infoOpen: false,
    };
  }

  componentDidMount() {
    const now = new Date();

    if (now.getTime() >= consts.MAX_DATE.getTime()) {
      this.setState({
        loading: false,
        started: true,
        finished: true,
        timeEnd: consts.MAX_DATE,
      });
    } else {
      fetch(consts.HOST + "/journey-2021.json")
        .then(response => response.json())
        .then(body => {
          this._setJourneyState(body.map(j => ({
            ...j,
            timeStart: new Date(consts.START_DATE.getTime() + j.timeStart * 1000),
            timeEnd: new Date(consts.START_DATE.getTime() + j.timeEnd * 1000),
          })));
        })
        .catch(err => this.setState({ loading: false }));
    }
  }

  _setJourneyState(journey) {
    const startDate = consts.START_DATE;
    const journeyIndex = findJourneyIndex(journey);
    const started = journeyIndex > -1;
    const finished = journeyIndex >= journey.length;
    const timeStart = startDate.toString();
    const timeEnd = new Date(startDate.getTime() + journey[journey.length - 1].timeEnd).toString();

    this.setState({
      started,
      finished,
      timeStart,
      timeEnd,
      journey,
      journeyIndex,
      page: journeyIndex === -1 ? 0 : Math.floor(journeyIndex / consts.PAGINATION),
      loading: false,
    });

    if (!finished) {
      if (timeStart) {
        this._startTimer = setTimeout(
          () => this._startInterval(),
          new Date(timeStart).getTime() - new Date().getTime() - 1000
        );
      } else {
        this._startInterval();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this._loadInterval);
    clearTimeout(this._startTimer);
  }

  _startInterval() {
    if (!this._loadInterval) {
      this._loadInterval = setInterval(() => this._loadData(), 1000);
    }
  }

  _loadData() {
    this._setJourneyState(this.state.journey);
  }

  _closeInfo() {
    this.setState({ infoOpen: false });
  }

  _openInfo() {
    this.setState({ infoOpen: true });
  }

  render() {
    const currentTown = this.state.journey[this.state.journeyIndex];
    return (
      <div className="app">
        <Header
          currentTown={currentTown}
          openInfo={() => this._openInfo()}
        />
        <Sidebar
          page={this.state.page}
          loading={this.state.loading}
          firstTown={currentTown}
          journey={this.state.journey}
        />
        <MapContent
          currentTown={currentTown}
          started={this.state.started}
          finished={this.state.finished}
          timeStart={this.state.timeStart}
          timeEnd={this.state.timeEnd}
        />
        <InfoModal
          isOpen={this.state.infoOpen}
          close={() => this._closeInfo()}
        />
        <ModalBackdrop open={this.state.infoOpen} />
      </div>
    );
  }
}

export default App;
